import React, { useEffect } from "react";
import { Card, Checkbox, Collapse, Input, List, Space } from "antd";
import { DollarCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import lang from "../../../language/language";
import "../styles/style.css";
import {
  setQueryServiceList,
  setItemList,
  setQueryStaffList,
  setStaffChoosed,
} from "../slice";
import { formatCurrency } from "../../Common/components/FormatData";
import * as bookingThunk from "./../thunk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const { Search } = Input;
const { Panel } = Collapse;

const ShowServiceList = () => {
  const serviceList = useSelector((state) => state.booking.serviceList);
  const queryServiceList = useSelector(
    (state) => state.booking.queryServiceList
  );
  const staffChoosed = useSelector((state) => state.booking.staffChoosed);
  const itemList = useSelector((state) => state.booking.itemList);
  const queryStaffList = useSelector((state) => state.booking.queryStaffList);
  const currentTabs = useSelector((state) => state.booking.currentTab);
  const setting = useSelector((state) => state.booking.generalSetting);
  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTabs === "1" && queryServiceList?.staff_id) {
      dispatch(
        bookingThunk.getServiceList({
          ...queryServiceList,
          company_code: params?.companyCode,
          staff_id: queryServiceList.staff_id,
        })
      );
    } else if (currentTabs === "2") {
      dispatch(
        bookingThunk.getServiceList({
          ...queryServiceList,
          company_code: params?.companyCode,
          staff_id: null,
        })
      );
    }
  }, [queryServiceList.staff_id, currentTabs]);

  const onChoose = (data) => {
    let listItem = [...itemList];
    if (listItem.some((x) => x.service_id === data.service_id)) {
      // kiểm tra trạng thái chọn của service
      listItem = listItem.filter((x) => x.service_id !== data.service_id);
    } else {
      if (currentTabs === "1") {
        listItem.push({
          service_id: data.service_id,
          service_code: data.service_code,
          service_name: data.label,
          service_price: data.service_price,
          service_price_discount: data.service_price_discount,
          service_duration: data?.service_duration,
          ...staffChoosed,
        });
      } else {
        listItem.push({
          service_id: data.service_id,
          service_code: data.service_code,
          service_name: data.label,
          service_price: data.service_price,
          service_price_discount: data.service_price_discount,
        });
      }
    }
    if (currentTabs === "2") {
      dispatch(setStaffChoosed({}));
      dispatch(
        setQueryStaffList({
          ...queryStaffList,
          listServiceId: listItem.map((z) => z.service_id),
        })
      );
    }

    dispatch(setItemList(listItem));
  };

  const onSearch = (value) => {
    dispatch(setQueryServiceList({ ...queryServiceList, search: value }));
  };

  return (
    <Card title={lang.service_choosed}>
      <Search
        size="middle"
        className="i-search mb-15"
        placeholder={lang.find_service}
        enterButton
        onSearch={onSearch}
      />
      <div className="container-service-list">
        <Collapse bordered={false} accordion>
          {serviceList.map((item) => {
            return (
              <Panel
                header={<span className="f-16">{item.label}</span>}
                key={item.value}
              >
                <List
                  itemLayout="horizontal"
                  className="ml-5 list-scroll-service"
                  dataSource={item.children}
                  renderItem={(x) => {
                    const checkedData = itemList.filter(
                      (z) => z.service_id === x.service_id
                    )?.length;
                    return (
                      <List.Item key={x.service_id}>
                        <Space direction="vertical">
                          <Checkbox
                            onChange={() => onChoose(x)}
                            checked={checkedData > 0}
                          >
                            {x.label}
                          </Checkbox>
                        </Space>
                        <div className="f-13">
                          <DollarCircleOutlined />{" "}
                          <span
                            className={`${
                              x.service_price_discount && "line-through limiter"
                            }`}
                          >
                            {formatCurrency(x.service_price, setting)}
                          </span>{" "}
                          {x.service_price_discount && (
                            <span>
                              {formatCurrency(
                                x.service_price_discount,
                                setting
                              )}
                            </span>
                          )}{" "}
                          - <ClockCircleOutlined /> {x.service_duration}{" "}
                          {lang.minute}
                        </div>
                      </List.Item>
                    );
                  }}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </Card>
  );
};

export default ShowServiceList;
