import { configureStore } from '@reduxjs/toolkit'

import commonReducer from './modules/Common/slice'
import bookingReducer from './modules/Booking/slice'

export default configureStore({
  reducer: {
    common: commonReducer,
    booking: bookingReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});
