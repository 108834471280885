/* eslint-disable react/display-name */
import React from 'react';
import lang from './language/language';

// loginPhone
// regis
// checkin
import * as BookingRoute from './modules/Booking/constants/routes'
import BookingPage from './modules/Booking/pages/BookingPage';

const Routes = [
  {
    path: BookingRoute.ROUTE_BOOKING,
    exact: true,
    main: ({ match, props }) => <BookingPage match={match} props={props} />,
    name: lang.checkin,
    permission: 'ALL'
  }
];

export default Routes;
