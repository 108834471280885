import { createSlice } from '@reduxjs/toolkit';
import * as bookingThunk from './thunk';

export const BookingSlice = createSlice({
  name: 'booking',
  initialState: {
    currentStep: 0,
    currentTab: '1',
    customerInfo: {},
    itemList: [],
    staffList: [],
    serviceList: [],
    branchList: [],
    bookingSetting: {},
    generalSetting: {},
    queryStaffList: {
      listServiceId: [],
      listBranchId: [],
      search: ''
    },
    queryServiceList: {
      staff_id: null,
      search: ''
    },
    staffChoosed: {},
    branch: null,
    appointmentSetting: {},
    hourSelected: {},
    dateSelected: ''
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setItemList: (state, action) => {
      state.itemList = action.payload;
    },
    setQueryStaffList: (state, action) => {
      state.queryStaffList = action.payload;
    },
    setQueryServiceList: (state, action) => {
      state.queryServiceList = action.payload;
    },
    setStaffChoosed: (state, action) => {
      state.staffChoosed = action.payload;
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setStaffList: (state, action) => {
      state.staffList = action.payload;
    },
    setServiceList: (state, action) => {
      state.serviceList = action.payload;
    },
    setHour: (state, action) => {
      state.hourSelected = action.payload;
    },
    setDate: (state, action) => {
      state.dateSelected = action.payload;
    }
  },
  extraReducers: {
    [bookingThunk.staffList.fulfilled]: (state, action) => {
      state.staffList = action.payload;
    },
    [bookingThunk.getBranchList.fulfilled]: (state, action) => {
      state.branchList = action.payload;
    },
    [bookingThunk.getServiceList.fulfilled]: (state, action) => {
      state.serviceList = action.payload;
    },
    [bookingThunk.getGeneralSettingPublic.fulfilled]: (state, action) => {
      state.generalSetting = action.payload;
    },
    [bookingThunk.getAppointmentSetting.fulfilled]: (state, action) => {
      state.appointmentSetting = action.payload;
    }
  }
});

export const {
  setCurrentStep,
  setCurrentTab,
  setCustomerInfo,
  setItemList,
  setQueryStaffList,
  setQueryServiceList,
  setStaffChoosed,
  setBranch,
  setStaffList,
  setServiceList,
  setHour,
  setDate
} = BookingSlice.actions;

export default BookingSlice.reducer;
