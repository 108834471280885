import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Tabs, message } from 'antd';
import lang from '../../../language/language'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep, setCurrentTab, setItemList, setServiceList, setStaffChoosed, setStaffList } from '../slice';
import ShowInfo from './ShowInfo';
import ChooseByStaff from './ChooseByStaff';
import ChooseByService from './ChooseByService';

const StepStaffService = () => {
    const dispatch = useDispatch()
    const itemList = useSelector(state => state.booking.itemList)
    const staffChoosed = useSelector(state => state.booking.staffChoosed)
    // const queryServiceList = useSelector(state => state.booking.queryServiceList)
    // const queryStaffList = useSelector(state => state.booking.queryStaffList)

    useEffect(() => {
        clearData()
    }, [])
    const items = [
        {
            key: '1',
            label: lang.choose_by_staff,
            children: <ChooseByStaff />
        },
        {
            key: '2',
            label: lang.choose_by_service,
            children: <ChooseByService />
        }
    ];

    const clearData = () => {
        dispatch(setCurrentTab('1'))
        dispatch(setServiceList([]))
        dispatch(setStaffChoosed({}))
        dispatch(setStaffList([]))
        dispatch(setItemList([]))
    }

    const onChange = (key) => {
        clearData()
        dispatch(setCurrentTab(key))
    };

    const onNext = () => {
        if (!staffChoosed.staff_id) {
            message.error(lang.pls_choose_staff)
            return;
        }
        if (itemList.length === 0) {
            message.error(lang.pls_choose_service)
            return;
        }
        dispatch(setCurrentStep(2))
    }

    return (
        <Row>
            <ShowInfo />
            <Col md={24} xs={24}>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={onChange}
                    />
                </Card>
            </Col>
            <Col md={24} xs={24}>
                <div className='text-center mt-30'>
                    <Button
                        size="large"
                        type='primary'
                        onClick={onNext}
                    >
                        {lang.next_step}
                    </Button>
                </div>
            </Col>
        </Row>

    )
};
export default StepStaffService;
