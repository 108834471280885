import React from 'react';
import { useSelector } from 'react-redux';
import * as c from '../../../constants/config';

const Footer = () => {
  const isHome = useSelector(state => state.common.isHome);
  return (
    <div style={{ display: 'none' }}>
      {
        !isHome &&
        <footer className="main-footer pt-1 pb-1">
          <div className="float-right d-none d-sm-block font-14">
            <b>Version</b> 1.0.0
          </div>
          <strong className="font-14">Copyright © 2021 <a href="http://hawk.vn">{c.APP_TITLE}</a>.</strong> All rights reserved.
        </footer>
      }
    </div>
  );
};

export default Footer;
