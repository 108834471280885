import React, { useEffect, useState } from "react";
import { Button, Calendar, Card, Col, Row } from "antd";
import lang from "../../../language/language";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep, setDate, setHour } from "../slice";
import ShowInfo from "./ShowInfo";
import * as bookingService from "./../service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import dayjs from "dayjs";

const StepTime = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [hourList, setHourList] = useState([]);
  const branch = useSelector((state) => state.booking.branch);
  const hourSelected = useSelector((state) => state.booking.hourSelected);
  const dateSelect = useSelector((state) => state.booking.dateSelected);
  const [dayOffList, setDayOffList] = useState([]);
  const [openHour, setOpenHour] = useState([]);

  const appointmentSetting = useSelector(
    (state) => state.booking.appointmentSetting
  );

  const currentStep = useSelector((state) => state.booking.currentStep);
  const GetListDateByNumber = (number = 7) => {
    const result = [moment().format("YYYY-MM-DD")];
    for (let i = 0; i < number - 1; i++) {
      result.push(moment(result[i]).add(1, "days").format("YYYY-MM-DD"));
    }
    return result;
  };

  const getDayOffList = () => {
    bookingService
      .dayOffSetting({
        branch_id: branch?.branch_id,
        company_code: params?.companyCode,
      })
      .then((res) => {
        if (res) {
          setDayOffList(res?.data);
        }
      });
  };

  const getOpenHourList = () => {
    bookingService
      .openHourSetting({
        branch_id: branch?.branch_id,
        company_code: params?.companyCode,
      })
      .then((res) => {
        if (res) {
          setOpenHour(res?.data);
        }
      });
  };
  useEffect(() => {
    getDayOffList();
    getOpenHourList();
  }, []);

  useEffect(() => {
    return () => {
      if (currentStep < 2) {
        dispatch(setHour({}));
        dispatch(setDate(""));
      }
    };
  }, []);
  useEffect(() => {
    bookingService
      .getAppointmentHourList({
        company_code: params?.companyCode,
        branch_id: branch?.branch_id,
        appointment_date: dayjs(dateSelect).format("YYYY-MM-DD"),
      })
      .then((res) => {
        if (res?.data) {
          setHourList(res.data);
        }
      });
  }, [params?.companyCode, branch, dateSelect]);

  const onNext = () => {
    dispatch(setCurrentStep(3));
  };

  const onClickHour = (value) => {
    if (value) {
      dispatch(setHour(value));
    }
  };
  const onChangeDate = (value) => {
    dispatch(setDate(value));
  };

  // const GetListDateByNumber = (number = 7) => {
  //     const result = [moment().format('YYYY-MM-DD')];
  //     for (let i = 0; i < number - 1; i++) {
  //         result.push(moment(result[i]).add(1, 'days').format('YYYY-MM-DD'))
  //     }
  //     return result;
  // }

  return (
    <Row gutter={24}>
      <ShowInfo />
      <Col md={8} xs={24}>
        <Card title={lang.select_date}>
          <Calendar
            locale="en_US"
            fullscreen={false}
            headerRender={() => {
              // console.log(moment(new Date()).add(5, 'days'))
              return <div></div>;
            }}
            onChange={onChangeDate}
            value={dateSelect}
            disabledDate={(current) => {
              const listDayOff = dayOffList
                ?.filter((x) => x?.day_off_setting_status === 1)
                ?.map((a) =>
                  moment(
                    new Date().getFullYear() + "-" + a?.day_off_setting_date
                  ).format("YYYY-MM-DD")
                );
              openHour
                ?.filter((z) => z?.open_hour_setting_open === 2)
                ?.map((a) => {
                  const today = moment(); // Ngày hiện tại
                  let targetDay = moment().day(a?.open_hour_setting_day); // Ngày trong tuần từ openHour (1: Monday, 7: Sunday)

                  // Kiểm tra nếu ngày trong tuần này đã qua (trong quá khứ)
                  if (targetDay.isBefore(today, "day")) {
                    targetDay.add(7, "days"); // Cộng thêm 7 ngày để lấy ngày tương lai
                  }

                  const date = targetDay.format("YYYY-MM-DD");
                  listDayOff.push(date); // Đẩy vào danh sách
                });
              const allowDate = GetListDateByNumber(
                appointmentSetting.appointment_setting_booking_date_limit
              );
              const filteredAllowDate = allowDate?.filter(
                (date) => !listDayOff.includes(date)
              );
              return !filteredAllowDate?.includes(
                dayjs(current).format("YYYY-MM-DD")
              );
            }}
          />
        </Card>
      </Col>
      <Col md={16} xs={24}>
        <Card title={lang.select_hour}>
          <div className="hour-list">
            <Row gutter={8} className="text-center">
              {hourList
                ?.filter(
                  (x) => x?.value > moment().format("hh:mm A") && x?.allowBook
                )
                .map((z) => {
                  return (
                    <Col key={z.value} xs={6} md={4} className="mb-15">
                      <Button
                        type={
                          z.value === hourSelected?.value ? "primary" : "dashed"
                        }
                        onClick={() => onClickHour(z)}
                        size="middle"
                      >
                        {z.label}
                      </Button>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Card>
      </Col>
      <Col md={24} xs={24}>
        <div className="text-center mt-30">
          <Button size="large" type="primary" onClick={onNext}>
            {lang.next_step}
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default StepTime;
