import React from 'react'
import { Col, Row } from 'antd'
import ShowStaffList from './ShowStaffList';
import ShowServiceList from './ShowServicesList';

const ChooseByService = () => {
    return (
        <Row gutter={24}>
            <Col md={12} xs={24} >
                <ShowServiceList />
            </Col>
            <Col md={12} xs={24} >
                <ShowStaffList />
            </Col>
        </Row>
    )
}

export default ChooseByService;
