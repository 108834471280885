import { createAsyncThunk } from '@reduxjs/toolkit';
import * as bookingService from './service'

export const staffList = createAsyncThunk(
    'booking/staffList',
    async (data, thunkAPI) => {
        const response = await bookingService.getStaff(data);
        return response.data;
    }
);

export const getServiceList = createAsyncThunk(
    'booking/serviceList',
    async (data, thunkAPI) => {
        const response = await bookingService.getServiceTree(data);
        return response.data;
    }
);

export const getBranchList = createAsyncThunk(
    'booking/branch',
    async (data, thunkAPI) => {
        const response = await bookingService.getBranchList(data);
        return response.data;
    }
);

export const getStaffAutoTurnPublic = createAsyncThunk(
    'booking/staff',
    async (data, thunkAPI) => {
        const response = await bookingService.getStaffAutoTurnPublic(data);
        return response.data;
    }
);

export const getGeneralSettingPublic = createAsyncThunk(
    'booking/staff',
    async (data, thunkAPI) => {
        const response = await bookingService.getGeneralSetting(data);
        return response.data;
    }
);

export const getAppointmentSetting = createAsyncThunk(
    'booking/appointmentSetting',
    async (data, thunkAPI) => {
        const response = await bookingService.getAppointmentSetting(data);
        return response.data;
    }
);

// export const bookingSetting = createAsyncThunk(
//   'booking/bookingSetting',
//   async (data, thunkAPI) => {
//     const response = await bookingService.bookingSetting(data);
//     return response.data;
//   }
// );
