import Main from './Main';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Spinner from './themes/Antd/Spinner';
import { useSelector } from 'react-redux';
const App = (props) => {
  const loading = useSelector(state => state.common.loading);
  return (
    <Router>
      <div className='app-container' >
        <div >
          <Main />
          <Spinner loading={loading || false} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
};

export default App;
