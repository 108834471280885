import { Card, Col } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import {
  EnvironmentOutlined,
  FieldTimeOutlined,
  UserOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const ShowInfo = () => {
  const branch = useSelector((state) => state?.booking?.branch);
  const itemList = useSelector((state) => state?.booking?.itemList);
  const staffChoosed = useSelector((state) => state?.booking?.staffChoosed);
  const dateSelected = useSelector((state) => state?.booking?.dateSelected);
  const hourSelected = useSelector((state) => state?.booking?.hourSelected);
  const generalSetting = useSelector((state) => state?.booking?.generalSetting);

  return (
    <Col md={24} xs={24} className="mb-15">
      <Card>
        {branch.branch_id && (
          <>
            <h2>{branch?.branch_name && branch?.branch_name}</h2>
            <div>
              {branch?.branch_address && <EnvironmentOutlined />}{" "}
              {branch?.branch_address && branch?.branch_address}
            </div>
          </>
        )}
        <div>
          {dateSelected && <FieldTimeOutlined />}{" "}
          {dateSelected &&
            dayjs(dateSelected).format(
              generalSetting.general_setting_date_format
            )}{" "}
          {hourSelected?.label}
        </div>
        <div>
          {staffChoosed?.staff_fullname && <UserOutlined />}{" "}
          {staffChoosed?.staff_fullname}
        </div>
        <div>
          {itemList.length > 0 && <ContainerOutlined />}{" "}
          {itemList.map((z) => z?.service_name).toString()}
        </div>
      </Card>
    </Col>
  );
};
export default ShowInfo;
