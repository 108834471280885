import React from 'react'
import { Button, Card, Col, List, Radio, Row, message } from 'antd'
import lang from '../../../language/language'
import { useDispatch, useSelector } from 'react-redux';
import { setBranch, setCurrentStep } from '../slice';
import { formatPhone } from '../../Common/components/FormatData'

const StepLocation = () => {
    const dispatch = useDispatch()
    const branch = useSelector(state => state.booking?.branch)
    const branchList = useSelector(state => state.booking?.branchList)

    const onChange = (e) => {
        dispatch(setBranch(e.target.data))
    }

    const onNext = () => {
        if (branch?.branch_id) {
            dispatch(setCurrentStep(1))
        } else {
            message.error(lang.please_select_branch)
        }
    }

    return (
        <Row>
            <Col md={24} xs={24}>
                <Card>
                    <Radio.Group value={branch?.branch_id} onChange={onChange} style={{ width: '100%' }}>
                        <List
                            size="large"
                            dataSource={branchList}
                            renderItem={(item) => <List.Item
                                actions={[<Radio
                                    key={item}
                                    value={item.branch_id}
                                    data={item}
                                />]}
                            >
                                <List.Item.Meta
                                    title={<b>{item.branch_name}</b>}
                                    description={<div>
                                        <div>{item.branch_address}</div>
                                        <div>{formatPhone(item.branch_phone_number)}</div>
                                    </div>}
                                />
                            </List.Item>
                            }
                        />
                    </Radio.Group>
                </Card>

            </Col>
            <Col md={24} xs={24}>
                <div className='text-center mt-30'>
                    <Button
                        size="large"
                        type='primary'
                        onClick={onNext}
                    >
                        {lang.next_step}
                    </Button>
                </div>
            </Col>
        </Row>
    )
};
export default StepLocation;
